var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"guests"}},[_c('v-card-title',[_c('div',{staticClass:"title d-flex fw"},[(_vm.table.type == 'rect')?_c('v-icon',{staticClass:"mx-1",attrs:{"size":"28"}},[_vm._v("mdi-rectangle")]):_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-"+_vm._s(_vm.table.type))]),_c('b',{staticClass:"me-1"},[_vm._v("( "+_vm._s(_vm.table.number)+" )")]),_vm._v(" "+_vm._s(_vm.table.name)+" ")],1)]),_c('v-subheader',[_vm._v(" "+_vm._s(_vm._f("t")('table_guests'))+" "),_c('v-spacer'),_c('v-autocomplete',{ref:"auto",staticClass:"mb-1",style:({ maxWidth: _vm.breakpoint.xs ? '174px' : '100%' }),attrs:{"items":_vm.filteredGuests,"prepend-inner-icon":"mdi-account-plus-outline","outlined":"","rounded":"","placeholder":_vm.$t('add_guest_to_table'),"dense":"","hide-details":""},on:{"change":_vm.select},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":item.guests > _vm.table.seats - _vm.table.taken}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")])],1),_c('v-list-item-avatar',{staticStyle:{"min-width":"100px"}},[_c('span',{staticClass:"px-1",staticStyle:{"font-size":"9px"}},[_vm._v(" "+_vm._s(_vm._f("t")('pepole_number'))+" ")]),_c('span',{class:{ 'red--text text--lighten-3': item.guests > _vm.table.seats - _vm.table.taken }},[_vm._v(" "+_vm._s(item.guests)+" ")])])],1)]}}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0",staticStyle:{"height":"350px"}},[_c('v-data-iterator',{attrs:{"height":"350","page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"headers":_vm.headers,"items":_vm.selected,"hide-default-footer":"","fixed-header":"","hide-default-header":""},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',{staticClass:"py-3"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-center warning--text"},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-alert")]),_vm._v(" "+_vm._s(_vm._f("t")('empty_table'))+" ")],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")]),_c('v-list-item-subtitle',[_c('span',{staticClass:"px-1",staticStyle:{"font-size":"9px"}},[_vm._v(" "+_vm._s(_vm._f("t")('pepole_number'))+" ")]),_vm._v(" "+_vm._s(item.guests)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeGuest(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1),(_vm.selected.length-1 > index)?_c('v-divider'):_vm._e()]}}])})],1),_c('v-divider'),_c('v-subheader',[_c('span',{staticClass:"caption success--text"},[_vm._v(" "+_vm._s(_vm._f("t")('empty_seats'))+" ")]),_c('b',{staticClass:"px-1",staticStyle:{"font-size":"1.3rem"}},[_vm._v(_vm._s(_vm.table.seats - _vm.table.taken))]),_c('v-spacer'),_c('span',{staticClass:"caption error--text"},[_vm._v(" "+_vm._s(_vm._f("t")('seated_seats'))+" ")]),_c('b',{staticClass:"px-1",staticStyle:{"font-size":"1.3rem"}},[_vm._v(_vm._s(_vm.table.taken))])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":_vm.color,"rounded":"","outlined":"","loading":_vm.loading,"disabled":!_vm.selected.length},on:{"click":_vm.confirm}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.confirmIcon))]),_vm._v(" "+_vm._s(_vm._f("t")(_vm.confirmText))+" ")],1),_c('v-btn',{attrs:{"outlined":"","rounded":"","disabled":_vm.loading},on:{"click":_vm.cancel}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.cancelIcon))]),_vm._v(" "+_vm._s(_vm._f("t")(_vm.cancelText))+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }