<template>
  <v-card id="guests">
    
      
   
      <v-card-title>
          <div class="title d-flex fw">
                <v-icon v-if="table.type == 'rect'" size="28" class="mx-1">mdi-rectangle</v-icon>
                <v-icon v-else class="mx-1">mdi-{{table.type}}</v-icon>
                <b class="me-1">( {{table.number}} )</b>
                {{ table.name }}
          </div>
        
          
      </v-card-title>
      
      <v-subheader>
          <!-- <v-icon class="mx-1">mdi-account-group-outline</v-icon> -->
        {{ 'table_guests' | t }}
        <v-spacer></v-spacer>
        <v-autocomplete
                    :items="filteredGuests"
                    ref="auto"
                    class="mb-1"
                    prepend-inner-icon="mdi-account-plus-outline"
                    v-model="search"
                    outlined
                    rounded
                    :placeholder="$t('add_guest_to_table')"
                    dense
                    hide-details
                    @change="select"
                    :style="{ maxWidth: breakpoint.xs ? '174px' : '100%' }"
                    
                   >
                   <template v-slot:item="{ attrs, on, item}">
                       <v-list-item v-on="on" v-bind="attrs" :disabled="item.guests > table.seats - table.taken">
                           <v-list-item-content>
                               <v-list-item-title>
                                   {{ item.firstname }} {{ item.lastname }}
                               </v-list-item-title>
                           </v-list-item-content>
                           <v-list-item-avatar style="min-width: 100px">
                               <span  class="px-1" style="font-size: 9px">
                                   {{ 'pepole_number' | t }}
                               </span>
                               <span :class="{ 'red--text text--lighten-3': item.guests > table.seats - table.taken }">

                               {{item.guests}}  
                               </span>
                           </v-list-item-avatar>
                       </v-list-item>
                   </template>
          </v-autocomplete>
      </v-subheader>
      <v-divider></v-divider>
      <v-card-text style="height: 350px" class="pa-0">
         <v-data-iterator
         height="350"
        
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.itemsPerPage"
            :headers="headers"
            :items="selected"
            hide-default-footer
            fixed-header
            hide-default-header
         >  
            <template v-slot:no-data>
                <v-list-item  class="py-3" >
                    <v-list-item-content>
                        <v-list-item-title class="text-center warning--text">
                            <v-icon color="warning"> mdi-alert</v-icon>
                            {{  'empty_table' | t }}
                        </v-list-item-title>
                    </v-list-item-content>
               
                </v-list-item>
            </template>

            <template v-slot:item="{ item, index }">
                <v-list-item  >
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.firstname }} {{ item.lastname }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <span  class="px-1" style="font-size: 9px">
                                {{ 'pepole_number' | t }}
                            </span>
                            {{item.guests}}  
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action >
                        <v-btn icon color="error" @click="removeGuest(index)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-divider v-if="selected.length-1 > index"></v-divider>
            </template>

         </v-data-iterator>
      </v-card-text>
      <v-divider></v-divider>
        <v-subheader>
                <span class="caption success--text">
                    {{ 'empty_seats' | t }} 
                </span>
                <b class="px-1" style="font-size: 1.3rem">{{table.seats - table.taken }}</b>
                <v-spacer></v-spacer>
                <span class="caption error--text">
                    {{ 'seated_seats' | t }} 
                </span>
                <b class="px-1" style="font-size: 1.3rem">{{table.taken}}</b>
           </v-subheader>
     
        
      <v-card-actions>
          <v-btn :color="color" rounded outlined @click="confirm" :loading="loading" :disabled="!selected.length">
              <v-icon small>{{ confirmIcon}}</v-icon>
              {{ confirmText | t }}
          </v-btn>
          <v-btn  outlined rounded @click="cancel" :disabled="loading">
              <v-icon small >{{ cancelIcon }}</v-icon>
              {{ cancelText | t }}
          </v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
export default {
    props: {
        table: Object,
        title: String,
        color: {
            type: String,
            default: 'success'
        },
        confirmText: {
            type: String,
            default: 'ok'
        },
        confirmIcon: {
            type: String,
            default: 'mdi-check'
        },
        cancelText: {
            type: String,
            default: 'cancel'
        },
        cancelIcon: {
            type: String,
            default: 'mdi-close'
        },
        loading: Boolean
    },
    data: () => ({
        search: '',
        selected: [],
        items: [],
        options: {},
        pagination: {
            page: 1,
            itemsPerPage: 1000,
            get pageStart ()  {
                return (this.page * this.itemsPerPage) - 1
            },
            get pageStop ()  {
                return( this.page * this.itemsPerPage) + this.itemsPerPage
            },
            pageCount: 10,
            itemsLength: 0
        },
        headers: [ {
                    text: 'firstname',
                    value: 'firstname',
                    align: 'start',
                },
                {
                    text: 'lastname',
                    value: 'lastname',
                    align: 'start',
                },
                { text: 'guests', value: 'guests' },
                { text: 'table', value: 'table.name' },]
    }),
    methods: {
        removeGuest (index) {
            this.table.taken -= Number(this.selected[index].guests)
            this.selected.splice(index,1)
        },
        select (guest) {
            const { auto } = this.$refs
            this.selected.push(guest)
            auto.reset()
            this.table.taken += Number(guest.guests)
            
        },
        confirm () {
            this.$emit('confirm', this.selected)
        },
        cancel () {
            this.$emit('cancel')
        }
    },
    computed: {
        filteredGuests () {
            return this.items.filter(e => {
                return this.selected.findIndex(ee => ee.id == e.id) === -1
            })
        }
    },
    async created () {
        this.dialogLoading(true)
        const db = this.database
        const guests = db.collection(`users/${this.currentUser.uid}/guests`).where('table', '==', null)
        this.items = (await guests.get()).docs.map(e => ({ id: e.id, ...e.data() }))
        if (this.table.guests && this.table.guests.length) {
            this.items.push(...this.table.guests)
            this.selected = [...this.table.guests]
        }
    
        this.pagination.itemsLength = this.items.length
        this.dialogLoading(false)
    }
}
</script>

<style lang="scss">
#guests {
    #v-data-footer .v-data-footer__pagination {
        margin: 0;
    }
}
</style>